<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('table.add_business_sector')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form>
                    <v-select
                            v-if="show"
                            v-model="editedData.provinceId"
                            :items="province"
                            :label="$t('form.select_province')"
                            item-value="id"
                            item-text="value"
                    ></v-select>
                    <v-text-field
                            v-model="editedData.divisionType"
                            :error-messages="divisionTypeErrors"
                            :label="$t('form.sector_type')"
                            required
                            @input="$v.editedData.divisionType.$touch()"
                            @blur="$v.editedData.divisionType.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.divisionName"
                            :error-messages="divisionNameErrors"
                            :label="$t('form.sector_name')"
                            required
                            @input="$v.editedData.divisionName.$touch()"
                            @blur="$v.editedData.divisionName.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.divisionCode"
                            :error-messages="divisionCodeErrors"
                            :label="$t('form.sector_code')"
                            required
                            @input="$v.editedData.divisionCode.$touch()"
                            @blur="$v.editedData.divisionCode.$touch()"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('form.cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('form.save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success"],
        computed: {
            divisionTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.divisionType.$dirty) return errors;
                !this.$v.editedData.divisionType.required &&
                errors.push("SectorType is required");
                return errors;
            },
            divisionNameErrors() {
                const errors = [];
                if (!this.$v.editedData.divisionName.$dirty) return errors;
                !this.$v.editedData.divisionName.required &&
                errors.push("Sectorname is required.");
                return errors;
            },
            divisionCodeErrors() {
                const errors = [];
                if (!this.$v.editedData.divisionCode.$dirty) return errors;
                !this.$v.editedData.divisionCode.required &&
                errors.push("SectorCode is required.");
                return errors;
            },
            provinceIdErrors() {
                const errors = [];
                if (!this.$v.editedData.provinceId.$dirty) return errors;
                !this.$v.editedData.provinceId.required &&
                errors.push("Province is required.");
                return errors;
            }
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                province: [],
                show: false,
                updated: false,
                editedData: {
                    divisionType: "",
                    divisionCode: "",
                    divisionName: "",
                    provinceId: 0
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                divisionType: {required},
                provinceId: {required},
                divisionCode: {required},
                divisionName: {required}
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                this.updated = false;
                let permissionData = JSON.parse(localStorage.getItem("tokenData"));
                if (permissionData.role == "Ministry") {
                    this.show = true;
                    const provinceRequest = await axios.get("Province/DDLProvinceList");
                    this.province = provinceRequest.data;
                } else if (permissionData.role == "Province") {
                    this.editedData.provinceId = permissionData.ProvinceID;
                } else if (permissionData.role == "Division") {
                    this.editedData.provinceId = permissionData.ProvinceID;
                    this.editedData.divisionId = permissionData.DivisionID;
                }
                console.log(permissionData, this.editedData);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        ProvinceID: parseInt(this.editedData.provinceId),
                        DivisionName: this.editedData.divisionName,
                        DivisionCode: this.editedData.divisionCode,
                        DivisionType: this.editedData.divisionType
                    };
                    axios.post("Division/CreateDivision", param).then(response => {
                        this.$v.$reset();
                        this.editedData = {};
                        this.servicesData.data = response.data
                        this.servicesData.message = "Sectoradded Succcessfully"
                        this.servicesData.color = "success"
                        this.dialogueClose()
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.divisionName = "";
                this.editedData.divisionType = "";
                this.editedData.divisionCode = "";
            }
        }
    };
</script>

<style scoped>
</style>