<template>
    <v-content>
        <v-snackbar v-model="snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-dialog v-model="dialogCreate" max-width="500px">
            <template v-slot:activator="{ on }">
                <!--<v-btn
                        color="primary"
                        dark
                        class="mb-2 float-right"
                        v-on="on"
                        :success="success"
                >{{$t('table.add_business_sector')}}
                </v-btn>-->
            </template>
            <createDivisionForm :success="success" @formResponse="onResponse"/>
        </v-dialog>
        <v-dialog v-model="dialogEdit" max-width="500px">
            <editDivisionForm
                    v-if="dialogEdit"
                    :divisionId="editedData.divisionId"
                    :success="success"
                    @formResponse="onResponse"
            />
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <v-expansion-panels hover>
            <v-expansion-panel>
                <v-expansion-panel-header>{{$t('table.filter')}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid>
                        <v-row align="center" class="mt-4">
                            <v-col class="float-right" cols="12" sm="6" v-if="isMinistry||isSuperAdmin">
                                <v-select
                                        v-model="editedData.provinceId"
                                        :items="province"
                                          dense
                                          outlined
                                        :label="$t('form.select_province')"
                                        item-value="id"
                                        item-text="value"
                                        clearable
                                ></v-select>
                            </v-col>
                            <v-col class="float-right" cols="12" sm="6">
                                <v-text-field :label="$t('form.sector_name')" v-model="serverParams.DivisionName"
                                              clearable
                                              dense
                                          outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-btn color="primary" dark @click="searchInput" class="mr-1">
                                <i class="material-icons">search</i>{{$t('search')}}
                            </v-btn>
                            <v-btn color="rgb(157, 25, 25)" dark @click="clearInput">
                                <i class="material-icons">clear</i>{{$t('clear')}}
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :totalRows="totalRecords"
                :isLoading.sync="isLoading"
                :pagination-options="{ enabled: true, }"
                :rows="rows"
                :columns="columns"
        >
            <!--<template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'actions'">
                  <v-icon small class="mr-2" @click="editItem(props.row)">edit</v-icon>
                  <v-icon small @click="deleteItem(props.row)">delete</v-icon>
                </span>
            </template>-->
        </vue-good-table>
    </v-content>
</template>

<script>
    import axios from "axios";
    import createDivisionForm from "./create";
    import editDivisionForm from "./edit";
    import deleteListData from "../../components/deleteModal";
    import {parseJwt} from "@/mixins/parseJwt";
    import {mapGetters} from 'vuex'

    export default {
        name: "DivisionList",
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {

                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters(['getSystemUserData']),
            isMinistry(){
                return this.getSystemUserData.role === 'Ministry'
            },
            isSuperAdmin(){
                return this.getSystemUserData.role === 'SuperAdmin'
            },
            columns(){
                return [
                    {
                        label: this.$t('table.province'),
                        field: "provinceName"
                    },
                    {
                        label: this.$t('table.business_sector'),
                        field: "divisionName"
                    },
                    {
                        label: this.$t('table.sector_code'),
                        field: "divisionCode"
                    },
                    //{
                    //    label: this.$t('table.actions'),
                    //    field: "actions",
                    //    sortable: false
                    //}
                ]
            }
        },
        components: {
            createDivisionForm,
            editDivisionForm,
            deleteListData
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                items: ["Foo", "Bar", "Fizz", "Buzz"],
                province: [],
                deleteFormData: {},
                popout: true,
                isLoading: false,
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "divisionName",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 10
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    divisionType: "",
                    divisionId: "",
                    divisionCode: "",
                    divisionName: "",
                    provinceId: ""
                }
            };
        },

        mounted() {
            this.loadItems();
            if(this.isSuperAdmin || this.isMinistry){
                this.filterData();
            }
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            onResponse(data) {
                if (data) {
                    this.dialogDelete = false
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar =!!data.message
                    this.loadItems();
                }
            },
            editItem(props) {
                this.editedData.divisionId = props.divisionID;
                this.dialogEdit = true;
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Division/DeleteDivision/" + props.divisionID;
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            searchInput() {
                this.loadItems();
            },
            clearInput() {
                this.serverParams.DivisionName = null
                this.editedData.provinceId = null
                this.loadItems();
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            async filterData() {
                const provinceRequest = await axios.get("Province/DDLProvinceList");
                this.province = provinceRequest.data;
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                this.isLoading = true
                let provinceId= parseInt(this.editedData.provinceId)
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    DivisionName: this.serverParams.DivisionName,
                    provinceId
                };
                try {
                    const response = await axios.post("Division/DivisionList", param);
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                    this.isLoading = false
                } catch (e) {
                    this.isLoading = false
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }
</style>
